import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { isAddress } from '../../utils/index.js'
// import TetuLogo from '../../assets/tetu.png'
// import WbtcLogo from '../../assets/wbtc.png'
// import DinoLogo from '../../assets/dino.jpg'
// import miFARMLogo from '../../assets/ifarm.png'
// import iceLogo from '../../assets/ice.png'
// import klimaLogo from '../../assets/klima.jpg'
// import untLogo from '../../assets/unt.png'
// import umaLogo from '../../assets/uma.png'
// import clamLogo from '../../assets/clam.png'
// import tetuQiLogo from '../../assets/tetuQi.svg'
// import usdcLogo from '../../assets/usdc.svg'
// import usdtLogo from '../../assets/usdt.svg'
// import maticlogo from '../../assets/matic.svg'
import zenlogo from '../../assets/zen.png'
import ascentlogo from '../../assets/ascentlogo.svg'
import DEFAULT_TOKEN_LIST from '../../constants/token_list.json'

const BAD_IMAGES = {}

const Inline = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`

const Image = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

export default function TokenLogo({ address, header = false, size = '24px', ...rest }) {
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
  }, [address])

  if (error || BAD_IMAGES[address]) {
    return (
      <Inline>
        <span {...rest} alt={''} style={{ fontSize: size }} role="img" aria-label="face">
          🤔
        </span>
      </Inline>
    )
  }

  let path
  if (address?.toLowerCase() === '0x01454691Ce0F245D952faD9A6B309A4B5a7D5b0A'.toLowerCase()) {
    path = ascentlogo
  } else if (address?.toLowerCase() === '0xF5cB8652a84329A2016A386206761f455bCEDab6'.toLowerCase()) {
    path = zenlogo
  } else {
    const list = DEFAULT_TOKEN_LIST.tokens.filter((x) => (address && x.address.toLowerCase() === address?.toLowerCase()))
    path =
      list.length > 0
        ? list[0].logoURI
        : `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/polygon/assets/${isAddress(
            address
          )}/logo.png`
  }

  return (
    <Inline>
      <Image
        {...rest}
        alt={''}
        src={path}
        size={size}
        onError={(event) => {
          BAD_IMAGES[address] = true
          setError(true)
          event.preventDefault()
        }}
      />
    </Inline>
  )
}
